import Routes from '~/common/routes'
import { Button } from '~/design-system'

export default function NotFoundFallback() {
  return (
    <div className="flex justify-center">
      <div className="max-w-page-container pt-40 flex flex-col justify-center gap-y-4">
        <h1 className="title-xl text-center">Not found!</h1>
        <p className="body-lg text-center">
          The resource you are looking for was not found
        </p>
        <Button to={Routes.HOME} fullWidth>
          Go back
        </Button>
      </div>
    </div>
  )
}
